define("nfe-customer-console-v2/routes/companies/service-invoices/index", ["exports", "nfe-customer-console-v2/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ServiceInvoicesRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class ServiceInvoicesRoute extends _authenticated.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _defineProperty(this, "titleToken", "Notas fiscais da empresa");
    }

    async model() {
      let params = this.paramsFor('companies.service-invoices');
      let account = await this.sessionData.getCurrentAccount();
      const subscription = await this.store.queryRecord('account', {
        account_id: account.id
      });
      return {
        accountId: account.id,
        companyId: params.companyId,
        parentId: account.parentId,
        subscription
      };
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('page', 1);
        controller.set('perPage', 10);
        controller.set('status', "none");
        controller.set('issueBegin', null);
        controller.set('borrowerFederalTaxNumber', null);
        controller.set('allSearchProps', controller.allSearchProps.map(element => {
          if (Ember.isPresent(element.value)) delete element.value;
          return element;
        }));
        controller.set('exportNfseModal', false);
        controller.set('exportCsvModal', false);
        controller.set('exportResponseModal', false);
      }
    }

    refreshRoute() {
      this.refresh();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshRoute", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "refreshRoute"), _class.prototype)), _class));
  _exports.default = ServiceInvoicesRoute;
});