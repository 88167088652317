define("nfe-customer-console-v2/adapters/service-invoice-query", ["exports", "nfe-customer-console-v2/adapters/base-rest", "nfe-customer-console-v2/config/environment", "ember-data-url-templates"], function (_exports, _baseRest, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ServiceInvoiceQueryAdapter = (_dec = Ember.inject.service, (_class = (_temp = class ServiceInvoiceQueryAdapter extends _baseRest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _defineProperty(this, "host", _environment.default.APP.FRONTEND_URL);

      _defineProperty(this, "apikeyType", 'Nota Fiscal (api.nfe.io)');

      _defineProperty(this, "queryUrlTemplate", '{+host}/companies/{company_id}/serviceinvoices{?pageIndex,pageCount,status,createdBegin,createdEnd,issuedBegin,issuedEnd,borrowerFederalTaxNumber}');

      _defineProperty(this, "urlSegments", {
        // The urlSegments property declared here override the urlSegments from ember-date-url-templates addon
        // that is why I declared the host again
        host() {
          return this.host;
        },

        account_id(type, id, snapshot, query) {
          return query.accountId;
        },

        company_id(type, id, snapshot, query) {
          return query.companyId;
        },

        pageIndex(type, id, snapshot, query) {
          return query.page;
        },

        pageCount(type, id, snapshot, query) {
          return query.perPage;
        },

        status(type, id, snapshot, query) {
          return query.status;
        },

        createdBegin(type, id, snapshot, query) {
          return query.createdBegin;
        },

        createdEnd(type, id, snapshot, query) {
          return query.createdEnd;
        },

        issuedBegin(type, id, snapshot, query) {
          return query.issuedBegin;
        },

        issuedEnd(type, id, snapshot, query) {
          return query.issuedEnd;
        },

        borrowerFederalTaxNumber(type, id, snapshot, query) {
          return query.borrowerFederalTaxNumber;
        }

      });
    }

    get headers() {
      const {
        apiKeys,
        id,
        parentId
      } = this.sessionData.account;
      const {
        key,
        data
      } = apiKeys?.find(({
        description
      }) => description === this.apikeyType) || {};

      if (!apiKeys?.length || !key || data != 'Active') {
        console.error(`No Apikey (${this.apikeyType}) found for account (id/parentId) ${parentId || id}`);
        return {};
      }

      return {
        Authorization: apiKeys.find(({
          description
        }) => description === this.apikeyType)?.key || null
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ServiceInvoiceQueryAdapter;
});