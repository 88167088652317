define("nfe-customer-console-v2/adapters/account", ["exports", "nfe-customer-console-v2/adapters/base-rest", "nfe-customer-console-v2/config/environment"], function (_exports, _baseRest, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AccountAdapter extends _baseRest.default {
    async query() {
      return await super.ajax(`${this.host}/accounts`, 'GET', {
        headers: this.headersToken
      });
    }

    async queryRecord(adapter, model, params) {
      return await super.ajax(`${this.host}/accounts/${params.account_id}`, 'GET', {
        headers: this.headersToken
      });
    }

    async findRecord(adapter, model, account_id) {
      return await super.ajax(`${this.host}/accounts/${account_id}`, 'GET', {
        headers: this.headersToken
      });
    }

    create(model) {
      return super.ajax(`${this.host}/accounts`, 'POST', {
        data: model,
        headers: this.headersToken
      });
    }

    update(model) {
      return super.ajax(`${this.host}/accounts/${model.id}`, 'PUT', {
        data: model.serialize(),
        headers: this.headersToken
      });
    }

    setEnvironment(model, environment) {
      const url = this.buildURL('account', model.get('id')) + `/set-environment/${environment}`;
      return super.ajax(url, 'PUT', {
        headers: this.headersToken
      });
    }

    activate(model) {
      const url = this.buildURL('account', model.get('id')) + "/activate";
      return super.ajax(url, 'PUT', {
        headers: this.headersToken
      });
    }

    deactivate(model) {
      const url = this.buildURL('account', model.get('id')) + "/deactivate";
      return super.ajax(url, 'PUT', {
        headers: this.headersToken
      });
    }

    updateUsage(model, beginDate, endDate) {
      return super.ajax(`${_environment.default.APP.USAGES_API_URL}/api/orchestrators/accounts/${model.get('id')}/usages/${beginDate}/${endDate}/sum`, 'PUT', {
        headers: this.headersToken
      });
    }

    updateUsageAll(beginDate, endDate) {
      return super.ajax(`${_environment.default.APP.USAGES_API_URL}/api/orchestrators/accounts/*/usages/${beginDate}/${endDate}/sum`, 'PUT', {
        headers: this.headersToken
      });
    }

    async getAllApiKeys(model, filter = null) {
      const result = await super.ajax(`${_environment.default.APP.IDENTITY_API_URL}/subjects/${model.id}/grants`);
      if (!filter) filter = () => true;
      return result.filter(filter);
    }

  }

  _exports.default = AccountAdapter;
  ;
});