define("nfe-customer-console-v2/services/session", ["exports", "ember-simple-auth/services/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SessionService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = (_temp = class SessionService extends _session.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "user", _descriptor2, this);

      _initializerDefineProperty(this, "features", _descriptor3, this);

      _initializerDefineProperty(this, "provider", _descriptor4, this);
    }

    requireAuthentication(transition, routeOrCallback) {
      if (transition?.intent) {
        const {
          url,
          name
        } = transition.intent;
        const currentIntent = url || name;

        if (currentIntent && currentIntent !== '/' && !currentIntent.includes('error')) {
          window.localStorage.setItem('intentUrl', currentIntent);
        }
      }

      if (super.requireAuthentication) {
        return super.requireAuthentication(transition, routeOrCallback);
      }
    }

    async handleAuthentication() {
      if (super.handleAuthentication) {
        super.handleAuthentication(null);
      }
    }

    async authenticationSucceeded() {
      Ember.debug(`authentication: succeeded`);
      await this.user.defineLocale.perform();
      const intentUrl = window.localStorage.getItem('intentUrl');
      Ember.debug(`authentication: intent url ${intentUrl}`);
      const invalidPaths = ['logout', 'login', 'error'];
      const isValidIntentUrl = intentUrl && intentUrl !== 'undefined' && intentUrl !== '/' && !invalidPaths.some(path => intentUrl.includes(path));

      if (isValidIntentUrl) {
        return intentUrl;
      }

      if (this.user.isAdmin) {
        return 'dashboard';
      } // if there is no account, redirect to create an account


      const account = await this.sessionData.getCurrentAccount();

      if (!account) {
        return 'create-account';
      } // if there is not feature enabled, redirect to account
      // if (!this.provider.hasAccountAndSomeFeature()) {
      //     return 'account';
      // }
      // if the service invoice onboarding is disabled, redirect to onboarding


      if (this.get('features.dFeTechServiceInvoice') && !this.get('features.dFeTechOnboardingServiceInvoice')) {
        return 'onboard.service-invoice';
      }

      if (this.get('features.dFeTechServiceInvoice') || this.get('features.dFeTechProductInvoice')) {
        return 'dashboard';
      }

      return 'account';
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "provider", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SessionService;
});