define("nfe-customer-console-v2/adapters/service-invoice", ["exports", "nfe-customer-console-v2/adapters/base-rest", "nfe-customer-console-v2/config/environment", "ember-data-url-templates"], function (_exports, _baseRest, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ServiceInvoiceAdapter = (_dec = Ember.inject.service, (_class = (_temp = class ServiceInvoiceAdapter extends _baseRest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _defineProperty(this, "host", _environment.default.APP.FRONTEND_URL);

      _defineProperty(this, "apikeyType", 'Nota Fiscal (api.nfe.io)');

      _defineProperty(this, "findRecordUrlTemplate", '{+host}/companies/{company_id}/serviceInvoices{/id}');

      _defineProperty(this, "createRecordUrlTemplate", '{+host}/companies/{company_id}/serviceInvoices');

      _defineProperty(this, "updateRecordUrlTemplate", '{+host}/companies/{company_id}/serviceInvoices{/id}');

      _defineProperty(this, "deleteRecordUrlTemplate", '{+host}/companies/{company_id}/serviceInvoices{/id}');

      _defineProperty(this, "urlSegments", {
        // The urlSegments property declared here override the urlSegments from ember-date-url-templates addon
        // that is why I declared the host again
        host() {
          return this.host;
        },

        id(type, id, snapshot, query) {
          return id;
        },

        company_id(type, id, snapshot, query) {
          if (snapshot.adapterOptions && snapshot.adapterOptions.company_id) return snapshot.adapterOptions.company_id;
          return query.companyId;
        }

      });
    }

    get headers() {
      const {
        apiKeys,
        id,
        parentId
      } = this.sessionData.account;
      const {
        key,
        data
      } = apiKeys?.find(({
        description
      }) => description === this.apikeyType) || {};

      if (!apiKeys?.length || !key || data != 'Active') {
        console.error(`No Apikey (${this.apikeyType}) found for account (id/parentId) ${parentId || id}`);
        return {};
      }

      return {
        Authorization: apiKeys.find(({
          description
        }) => description === this.apikeyType)?.key || null
      };
    }

    reprocessWebhook(companyId, invoiceId) {
      const url = this.buildURL('service-invoce', invoiceId, {
        adapterOptions: {
          company_id: companyId
        }
      }, 'updateRecord');
      return super.ajax(url + `/delivery-webhooks`, "PUT");
    }

    sendEmail(companyId, invoiceId) {
      const url = this.buildURL('service-invoce', invoiceId, {
        adapterOptions: {
          company_id: companyId
        }
      }, 'updateRecord');
      return super.ajax(url + `/sendemail`, "PUT");
    }

    cancelInvoice(companyId, invoiceId) {
      const url = this.buildURL('service-invoce', invoiceId, {
        adapterOptions: {
          company_id: companyId
        }
      }, 'deleteRecord');
      return super.ajax(url, "DELETE");
    }

    handleResponse(status, headers, payload, requestData) {
      // Prevent exception when ember-data try to parse an empty text response as JSON
      if (requestData.method === "PUT" && requestData.url.includes("/sendemail")) return super.handleResponse(204, headers, payload, requestData);

      if (status >= 400 && payload && payload.message) {
        payload = {
          errors: [{
            status,
            detail: payload.message
          }]
        };
      }

      return super.handleResponse(status, headers, payload, requestData);
    }

    ajaxOptions(url, type, options) {
      let hash = super.ajaxOptions(...arguments);
      if (type === "PUT" && (url.includes("/sendemail") || url.includes("/delivery-webhooks"))) hash.dataType = "text";
      return hash;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ServiceInvoiceAdapter;
  ;
});