define("nfe-customer-console-v2/controllers/companies/service-invoices/index", ["exports", "nfe-customer-console-v2/models/enums", "ember-parachute", "moment-timezone", "ember-concurrency"], function (_exports, _enums, _emberParachute, _momentTimezone, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ServiceInvoiceListQueryParams = void 0;
  let date = new Date();
  let startDate = (0, _momentTimezone.default)(date).subtract(1, 'months').endOf('month').toString();
  let curDate = date.toISOString();

  _momentTimezone.default.tz.setDefault("America/Sao_Paulo");

  let timezoneGMT = (0, _momentTimezone.default)().tz(_momentTimezone.default.tz.guess()).format('Z');
  const ServiceInvoiceListQueryParams = new _emberParachute.default({
    page: {
      as: 'page',
      defaultValue: 1,
      refresh: true,
      replace: true
    },
    perPage: {
      as: 'perPages',
      defaultValue: 10,
      refresh: true,
      replace: true
    },
    status: {
      as: 'status',
      defaultValue: _enums.default.nfseStatuses[0].id,
      refresh: true,
      replace: true,

      serialize(status) {
        return status;
      },

      deserialize(status) {
        if (status != 'issued' && status != 'cancelled' && status != 'error' && status != 'created' && status != 'none') {
          status = 'none';
        }

        return status;
      }

    },
    createdBegin: {
      as: 'createdBegin',
      defaultValue: null,
      refresh: true,
      replace: true
    },
    createdEnd: {
      as: 'createdEnd',
      defaultValue: null,
      refresh: true,
      replace: true
    },
    issuedBegin: {
      as: 'issuedBegin',
      defaultValue: null,
      refresh: true,
      replace: true
    },
    issuedEnd: {
      as: 'issuedEnd',
      defaultValue: null,
      refresh: true,
      replace: true
    },
    borrowerFederalTaxNumber: {
      as: 'federalTaxNumber',
      defaultValue: null,
      refresh: true,
      replace: true
    }
  });
  _exports.ServiceInvoiceListQueryParams = ServiceInvoiceListQueryParams;

  var _default = Ember.Controller.extend(ServiceInvoiceListQueryParams.Mixin, {
    serviceInvoice: Ember.inject.service(),
    user: Ember.inject.service(),
    store: Ember.inject.service(),
    ticket: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    intl: Ember.inject.service(),
    export: Ember.inject.service(),
    perPageOptions: Ember.A([10, 20, 30, 40, 50]),
    queryParamsChanged: Ember.computed.or('queryParamsState.{page,perPage,status,issuedBegin,issuedEnd,createdBegin,createdEnd,borrowerFederalTaxNumber}.changed'),
    invoiceErrorMessage: null,
    invoice: null,
    exportNotify: false,
    exportEmails: null,
    exportTypes: _enums.default.exportNfseTypes,
    exportStatuses: _enums.default.exportNfseStatuses,
    exportStatusesByType: {
      "analytical-csv": _enums.default.exportNfseStatuses,
      "pdf": _enums.default.exportNfseStatuses.filter(({
        code
      }) => ["none", "issued", "cancelled"].includes(code)),
      "xml": _enums.default.exportNfseStatuses.filter(({
        code
      }) => ["none", "issued", "cancelled"].includes(code))
    },
    isExportInvalid: Ember.computed('exportFilters.{type,status,beginOn,endOn}', function () {
      const filters = this.get('exportFilters');
      return Object.values(filters).some(Ember.isEmpty);
    }),
    allSearchProps: Ember.A([{
      field: 'federalTaxNumber',
      name: 'companies.invoices.filter.federalTaxNumber',
      type: 'number',
      mask: '(99.999.999/9999-99)|(999.999.999-99)'
    }, {
      field: 'status',
      name: 'companies.invoices.filter.status',
      type: 'radio',
      values: _enums.default.nfseStatuses
    }, {
      field: 'date-created-range',
      name: 'companies.invoices.filter.created',
      type: 'date',
      createdDate: {
        begin: new Date(),
        end: new Date()
      }
    }, {
      field: 'date-issued-range',
      name: 'companies.invoices.filter.issued',
      type: 'date',
      issuedDate: {
        begin: new Date(),
        end: new Date()
      }
    }, {
      field: 'curWeek',
      name: 'companies.invoices.filter.curWeek',
      type: 'date'
    }, {
      field: 'curMonth',
      name: 'companies.invoices.filter.curMonth',
      type: 'date'
    }, {
      field: 'lastMonth',
      name: 'companies.invoices.filter.lastMonth',
      type: 'date'
    }, {
      field: 'curYear',
      name: 'companies.invoices.filter.curYear',
      type: 'date'
    }]),
    maxDate: new Date(),
    actions: {
      transitionToNew(redirectToRoute, model) {
        let url = `http://account.nfe.io/`;
        url += `impersonate/acc_${model.parentId.replace("acc_", "")}`;

        if (redirectToRoute === "accounts.subscriptions.exports") {
          url += `?redirect=/accounts/acc_${model.parentId.replace("acc_", "")}/subscriptions/${model.subscription.id}/exports`;
        }

        window.open(url) || window.location.assign(url);
        return;
      },

      filter({
        type,
        field,
        value,
        queryName,
        date,
        createdDate,
        issuedDate
      }, action) {
        let queryParams = {};

        if (action == "remove") {
          switch (field) {
            case "status":
              queryParams.status = null;
              break;

            case "federalTaxNumber":
              queryParams.borrowerFederalTaxNumber = null;
              break;

            default:
              queryParams.createdBegin = null;
              queryParams.createdEnd = null;
              break;
          }
        } else {
          switch (type) {
            case "radio":
              if (field == "status") queryParams.status = value.id;
              break;

            case "number":
              if (field == "federalTaxNumber") queryParams.borrowerFederalTaxNumber = value;
              break;

            case "date":
              if (createdDate) {
                let createdBegin = (0, _momentTimezone.default)(createdDate.begin).format("YYYY-MM-DD");
                let createdEnd = (0, _momentTimezone.default)(createdDate.end).format("YYYY-MM-DD");
                queryParams.createdBegin = `${createdBegin}T00:00:00${timezoneGMT}`;
                queryParams.createdEnd = `${createdEnd}T23:59:59${timezoneGMT}`;
              }

              if (issuedDate) {
                let issuedBegin = (0, _momentTimezone.default)(issuedDate.begin).format("YYYY-MM-DD");
                let issuedEnd = (0, _momentTimezone.default)(issuedDate.end).format("YYYY-MM-DD");
                queryParams.issuedBegin = `${issuedBegin}T00:00:00${timezoneGMT}`;
                queryParams.issuedEnd = `${issuedEnd}T23:59:59${timezoneGMT}`;
              }

              break;
          }
        } // set pagination to default values


        queryParams.page = 1;
        this.transitionToRoute('companies.service-invoices', this.model.companyId, {
          queryParams
        });
      },

      changeExportType(type) {
        this.set('exportFilters.type', type);
        this.set('exportFilters.status', this.get('exportStatuses').find(({
          code
        }) => code == "none"));
      },

      tryDuplicateInvoice(invoice) {
        this.set('invoice', invoice);
        this.set('tryDuplicateInvoiceModal', true);
      },

      tryCancelInvoice(invoice) {
        this.set('invoice', invoice);
        this.set('tryCancelInvoiceModal', true);
      },

      showInvoiceError(invoice) {
        this.set('invoiceErrorMessage', invoice.get('flowMessage'));
        this.set('invoiceErrorMessageModal', true); // return false;
      },

      closeDialogWithParent() {
        this.set('invoiceErrorMessageModal', false);
        this.set('tryCancelInvoiceModal', false);
        this.set('tryDuplicateInvoiceModal', false);
      },

      mutPerPage(perPage) {
        this.set('perPage', perPage);
        this.set('page', 1);
      },

      mutPage(action, page) {
        if (action === "increment") {
          this.set('page', page + 1);
        } else {
          this.set('page', page - 1 < 1 ? 1 : page - 1);
        }
      },

      refresh() {
        this.send("refreshRoute");
      },

      openTicket(model, invoice) {
        let ticketForm = this.store.createRecord('ticket', {
          product: this.store.createRecord('ticket/product')
        });
        ticketForm.product.set('providerNamespace', "DFeTech.ServiceInvoice");
        ticketForm.product.set('companyId', model.companyId);
        ticketForm.product.set('invoiceId', invoice.id);
        ticketForm.set('category', 'Problema');
        ticketForm.set('department', 'Tecnico');
        ticketForm.set('type', 'produto');
        this.send('openTicketModal', ticketForm);
      },

      openExportModal(type) {
        let {
          status,
          createdBegin,
          createdEnd,
          issuedBegin,
          issuedEnd,
          isPeriodInvalid
        } = this.allQueryParams;
        let currDate = (0, _momentTimezone.default)(new Date());
        let lastmonth = currDate.subtract(1, 'months').endOf('month');

        let createdDateBegin = _momentTimezone.default.utc(createdBegin ? createdBegin : lastmonth).format("YYYY-MM-DD");

        let createdDateEnd = _momentTimezone.default.utc(createdEnd ? createdEnd : currDate).format("YYYY-MM-DD");

        let issuedDateBegin = issuedBegin ? _momentTimezone.default.utc(issuedBegin).format("YYYY-MM-DD") : null;
        let issuedDateEnd = issuedEnd ? _momentTimezone.default.utc(issuedEnd).format("YYYY-MM-DD") : null;
        createdBegin = `${createdDateBegin}T00:00:00${timezoneGMT}`;
        createdEnd = `${createdDateEnd}T23:59:59${timezoneGMT}`;

        if (issuedBegin || issuedEnd) {
          issuedBegin = `${issuedDateBegin}T00:00:00${timezoneGMT}`;
          issuedEnd = `${issuedDateEnd}T23:59:59${timezoneGMT}`;
        }

        isPeriodInvalid = false;

        if (type != "analytical-csv") {
          isPeriodInvalid = this.export.isExportNfsePeriodInvalid(issuedBegin ? issuedBegin : createdBegin, issuedEnd ? issuedEnd : createdEnd);
        }

        this.set('exportFilters', {
          type: this.get('exportTypes').find(({
            code
          }) => code == type),
          status: this.get('exportStatusesByType')[type].find(({
            code
          }) => code == status) ?? this.get('exportStatuses').find(({
            code
          }) => code == "none"),
          beginOn: issuedDateBegin ?? createdDateBegin,
          endOn: issuedDateEnd ?? createdDateEnd,
          isPeriodInvalid: isPeriodInvalid ?? false
        });
        this.set('exportModal', true);
      }

    },
    duplicateInvoice: (0, _emberConcurrency.task)(function* (companyId, invoiceId) {
      this.set('tryDuplicateInvoiceModal', false);
      return this.serviceInvoice.duplicate(companyId, invoiceId);
    }),
    cancelInvoice: (0, _emberConcurrency.task)(function* (companyId, invoice) {
      this.set('tryCancelInvoiceModal', false);
      return this.serviceInvoice.cancel(companyId, invoice);
    }),
    sendEmailInvoice: (0, _emberConcurrency.task)(function* (companyId, invoice) {
      return this.serviceInvoice.sendEmail(companyId, invoice);
    }),
    reprocessPdf: (0, _emberConcurrency.task)(function* (companyId, invoice) {
      return this.serviceInvoice.download('pdf', invoice, companyId, true);
    }),
    downloadInvoiceAction: (0, _emberConcurrency.task)(function* (type, invoice, companyId) {
      return this.serviceInvoice.download(type, invoice, companyId);
    }),
    reprocessWebhook: (0, _emberConcurrency.task)(function* (companyId, invoice) {
      return this.serviceInvoice.reprocessWebhook(companyId, invoice.id);
    }),
    exportNfse: (0, _emberConcurrency.task)(function* (filters) {
      const account = yield this.sessionData.getCurrentAccount();
      let options = {
        companyId: this.model.companyId,
        resource: `company-service-invoice-${filters.type.code}`,
        beginOn: filters.beginOn,
        endOn: filters.endOn,
        apiKey: account.apiKey,
        emails: this.exportEmails
      };

      if (filters.type.code == "analytical-csv") {
        options.status = filters.status.code == "none" ? ["Error", "Cancelled", "Issued"] : [filters.status.code];
      }

      if ((filters.type.code == "pdf" || filters.type.code == "xml") && filters.status.code != "none") {
        options.status = filters.status.code == "error" ? [] : [filters.status.code];
      }

      try {
        yield this.store.adapterFor('export').newExport(account.parentId, account.id, options);
        this.set('exportResponse', {
          success: true,
          message: this.intl.t("account.export.modal.success")
        });
      } catch (error) {
        console.log(error);
        this.set('exportResponse', {
          success: false,
          message: this.intl.t("account.export.modal.error")
        });
      }

      this.set('exportModal', false);
      this.set('exportResponseModal', true);
    })
  });

  _exports.default = _default;
});