define("nfe-customer-console-v2/controllers/onboard/index", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FETCH_DEBOUNCE = 1000;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    dfeTechProviders: Ember.A([]),
    dataTechProviders: Ember.A([]),

    init() {
      this._super(...arguments);

      this.dfeTechProviders = Ember.A([{
        id: 'DFeTech.ServiceInvoice',
        title: 'onboard.nfseIssuance.title',
        content: 'onboard.nfseIssuance.content',
        active: this.features.isEnabled(Ember.String.camelize('DFeTech.ServiceInvoice'))
      }, {
        id: 'DFeTech.ProductInvoice',
        title: 'onboard.nfeIssuance.title',
        content: 'onboard.nfeIssuance.content',
        active: this.features.isEnabled(Ember.String.camelize('DFeTech.ProductInvoice'))
      }, {
        id: 'DFeTech.Company',
        title: 'onboard.multiCompanies.title',
        content: 'onboard.multiCompanies.content',
        active: this.features.isEnabled(Ember.String.camelize('DFeTech.Company'))
      } // {
      //     id: 'DFeTech.ConsumerInvoice',
      //     title: 'onboard.nfceIssuance.title',
      //     content: 'onboard.nfceIssuance.content',
      //     active: false
      // },
      ]);
      this.dataTechProviders = Ember.A([{
        id: 'DataTech.Batch.ProductInvoice',
        title: 'onboard.nfeQuery.title',
        content: 'onboard.nfeQuery.content',
        active: this.features.isEnabled(Ember.String.camelize('DataTech.Batch.ProductInvoice'))
      }, {
        id: 'DataTech.LegalEntity',
        title: 'onboard.legalEntityQuery.title',
        content: 'onboard.legalEntityQuery.content',
        active: this.features.isEnabled(Ember.String.camelize('DataTech.LegalEntity'))
      }, {
        id: 'DataTech.NaturalPerson',
        title: 'onboard.naturalPersonQuery.title',
        content: 'onboard.naturalPersonQuery.content',
        active: this.features.isEnabled(Ember.String.camelize('DataTech.NaturalPerson'))
      }, {
        id: 'DFeTech.TransportationInvoice.Inbound',
        title: 'onboard.cteInbound.title',
        content: 'onboard.cteInbound.content',
        active: this.features.isEnabled(Ember.String.camelize('DFeTech.TransportationInvoice.Inbound'))
      }, {
        id: 'DFeTech.ProductInvoice.Inbound',
        title: 'onboard.nfeInbound.title',
        content: 'onboard.nfeInbound.content',
        active: this.features.isEnabled(Ember.String.camelize('DFeTech.ProductInvoice.Inbound'))
      }]);
    },

    // dfeTechInboundProviders: A([
    //   {
    //     id: 'DFeTech.TransportationInvoice.Inbound',
    //     title: 'onboard.cteInbound.title',
    //     content: 'onboard.cteInbound.content',
    //     active: false
    //   },
    //   {
    //     id: 'DFeTech.ProductInvoice.Inbound',
    //     title: 'onboard.nfeInbound.title',
    //     content: 'onboard.nfeInbound.content',
    //     active: false
    //   },
    // ]),
    noSelectedProvider: Ember.computed('dfeTechProviders.@each.active', 'dataTechProviders.@each.active', function () {
      return this.dfeTechProviders.filterBy('active', true).length === 0 && this.dataTechProviders.filterBy('active', true).length === 0; // this.dfeTechInboundProviders.filterBy('active', true).length === 0;
    }),
    activateProviders: (0, _emberConcurrency.task)(function* () {
      if (FETCH_DEBOUNCE > 0) yield (0, _emberConcurrency.timeout)(FETCH_DEBOUNCE);
      let providersToActive = this.providersToActive();

      try {
        let account = yield this.sessionData.getCurrentAccount();
        let providerAdapter = this.store.adapterFor('provider');

        for (let provider of providersToActive) {
          let result = yield providerAdapter.activateProvider(account.id, provider);

          if (result.provider.status == 'Active') {
            this.features.enable(result.provider.namespace);
            this.get('session.data.authenticated.current_account.providers')[Ember.String.camelize(result.provider.namespace)] = 'Active';
          }
        }

        this.session.store.persist(this.get('session.data'));

        if (providersToActive.includes("DFeTech.ServiceInvoice")) {
          this.router.replaceWith('onboard.service-invoice');
        } else {
          window.location.replace('/account');
        }
      } catch (error) {
        this.notify.error(this.intl.t('onboard.activateErrorMsg'));
      }
    }),

    providersToActive() {
      let providersToActive = ['AzHub.Billing', 'AzHub.Account', 'AzHub.Usage'];
      let providers = this.dfeTechProviders.concat(this.dataTechProviders);
      providers.map(provider => {
        if (provider.active) {
          if (provider.id === 'DataTech.Batch.ProductInvoice') {
            providersToActive.push('DataTech.Batch.ProductInvoice.Pdf', 'DataTech.Batch.ProductInvoice.Xml');
          } else {
            providersToActive.push(provider.id);
          }

          if (provider.id == 'DFeTech.ServiceInvoice' || provider.id == 'DFeTech.ProductInvoice') {
            providersToActive.push('DFeTech.WebHook', 'DFeTech.Mail');
          }
        }
      });
      providersToActive = providersToActive.uniq();
      return providersToActive;
    }

  });

  _exports.default = _default;
});