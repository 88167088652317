define("nfe-customer-console-v2/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    provider: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    user: Ember.inject.service(),
    ticket: Ember.inject.service(),
    detectRoute: Ember.observer('currentRouteName', async function () {
      if (this.session.isAuthenticated) {
        const currentRoute = this.router.currentRouteName;
        const accounts = this.get('session.data.authenticated.accounts');
        if (Ember.isEmpty(accounts)) return;
        const account = await this.sessionData.getCurrentAccount();
        if (Ember.isEmpty(account)) return;
        if (currentRoute == 'loading' || this.user.isAdmin) return;

        if (!this.get("features.azHubAccount") && currentRoute != 'onboard.index') {
          return this.router.replaceWith('onboard');
        }

        if (this.provider.hasAccountAndSomeFeature() && currentRoute == 'onboard.index' && this.get("features.dFeTechServiceInvoice")) return this.router.replaceWith('dashboard');
        if (currentRoute == 'dashboard' && !this.get("features.dFeTechServiceInvoice")) return this.router.replaceWith('account');
      }
    }),
    accountProviders: Ember.computed('session.data.authenticated.current_account.providers.{}', function () {
      return this.get("session.data.authenticated.current_account.providers");
    }),
    actions: {
      logout() {
        this.transitionToRoute('logout');
      }

    }
  });

  _exports.default = _default;
});